export const seo = {
  url: 'transport-miedzynarodowy',
  title: {
    pl: 'Kontenery Morskie | Transport Kontenerów',
    en: 'Shipping containers | Sales and distribution',
    zh: '海运集装箱 | 销售和配送',
  },
  desc: {
    pl: `✓ Kontenery Morskie ✓ Kontenery 20'DV ✓ Kontener 40'DV ✓Sklep z kontenerami ✓Szybka realizacja`,
    en: `✓ Container quote ✓ 20'DV containers ✓ 40'DV containers ✓Container store ✓Fast delivery`,
    zh: `✓ 集装箱报价 ✓ 20'DV 集装箱 ✓ 40'DV 集装箱 ✓ 集装箱商店 ✓ 快速交货`,
  },
  keywords: [
    'omida',
    'logistics',
    'transport',
    'europa',
    'transport międzynarodowy',
  ],
}

export const intro = {
  title: {
    pl: 'Usługi',
    en: 'Services',
    zh: '服务业',
  },
  desc: {
    pl: 'Kontenery dopasowane do specyfiki danej branży. Stawiamy na szybką realizację i dużą dostępność.',
    en: 'Containers tailored to your specific industry. We focus on fast delivery and high availability.',
    zh: '为您所在行业量身定制的集装箱。我们专注于快速交付以及确保高供货能力。',
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Read more',
      zh: '阅读更多',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        pl: 'Kontenery dla Twojego biznesu',
        en: 'Containers for your business',
        zh: '适合您所在行业的集装箱',
      },
      texts: [
        {
          pl: 'Szukasz kontenerów dopasowanych do specyfiki danej gałęzi przemysłu? Nasze doświadczony zespół dostarczy w dowolne miejsce w Europie kontener dla Twojego Biznesu. Wybierając Omida Trade masz możliwość personalizacji kontenerów oraz ich składowania.',
          en: 'Looking for containers tailored to your specific industry? Our experienced team will deliver to any place in Europe a container for your Business. By choosing Omida Trade you have the opportunity to personalize containers and their storage.',
          zh: '是否在寻找适合您所在行业的集装箱？我们的团队经验丰富，可将集装箱运送至欧洲的任何地点，为您的业务提供服务。选择 Omida Trade，您就有机会获得定制集装箱及其存储安排',
        },
      ],
    },
  ],
}

export const buttonBack = {
  text: {
    pl: 'Powrót do transportu',
    en: 'Back to transport',
    zh: 'Back to transport',
  },
  link: '/transport/',
}
